import {Injectable} from '@angular/core';
import {LogLevel} from './log-level.enum';
import {environment} from '../../../../environments/environment';

/**
 * @description Logger service for environment logging.
 */
@Injectable({
  providedIn: 'root'
})
export class Logger {
  
  // Default log level.
  // TODO: Change to environment variable.
  private level: LogLevel = LogLevel.ERROR;

  public debug(...message: any): void {
    Logger.writeToLog(LogLevel.DEBUG, ...message);
  }

  public log(...message: any) {
    Logger.writeToLog(LogLevel.INFO, ...message);
  }

  public info(...message: any) {
    Logger.writeToLog(LogLevel.INFO, ...message);
  }

  public warn(...message: any) {
    Logger.writeToLog(LogLevel.WARN, ...message);
  }

  public error(...message: any) {
    Logger.writeToLog(LogLevel.ERROR, ...message);
  }

  /**
   * Should write the log?
   */
private static shouldLog(level: LogLevel): boolean {
    
    const logLevelNumber = Number(environment.LOG_LEVEL);
    return (level >= logLevelNumber);
}

  /**
   * Write logs.
   */
  private static writeToLog(level: LogLevel, ...message: any) {
    
    if (this.shouldLog(level)) {
      if (level <= LogLevel.INFO) {
        console.log(Logger.getLogDate(), ...message);
      } else if (level === LogLevel.ERROR) {
        console.error(Logger.getLogDate(), ...message);
      } else if (level === LogLevel.WARN) {
        console.warn(Logger.getLogDate(), ...message);
      }
    }
  }

  /**
   * To add the date on logs.
   */
  private static getLogDate(): string {
    const date = new Date();
    return '[' +
      date.getUTCFullYear() + '/' +
      (date.getUTCMonth() + 1) + '/' +
      date.getUTCDate() + ' ' +
      date.getUTCHours() + ':' +
      date.getUTCMinutes() + ':' +
      date.getUTCSeconds() + '.' +
      date.getMilliseconds() + ']';
  }
}
