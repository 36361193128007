import { Component,OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from  '../../../_services';
import { ClassToggleService, HeaderComponent } from '@coreui/angular-pro';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Project } from '../../../shared/interface/Project';
import { freeSet } from '@coreui/icons';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',

})
export class DefaultHeaderComponent extends HeaderComponent implements OnInit {

  icons = freeSet;


  sessionCurrentProject = "sessionCurrentProject";

  currentProject:Project = <Project>{}


  @Input() sidebarId: string = "sidebar1";

  public isChecked = false;
  public llmModeLabel = "Fast";
  public isLogged = false;

  public visible = false;

  public themeSwitch = new UntypedFormGroup({
    themeSwitchRadio: new UntypedFormControl('dark-theme'),
  });

  constructor(private classToggler: ClassToggleService,
    private authenticationService: AuthenticationService) {
    super();
     this.classToggler.toggle('body', 'dark-theme');

    if (this.authenticationService.userValue){
      this.isLogged = true;
    }
  }
  ngOnInit(): void {

    // console.log(this.sessionCurrentProject)
    this.currentProject = JSON.parse(sessionStorage.getItem(this.sessionCurrentProject) || '{}');


    // console.log("Current Project: ", this.currentProject, this.currentProject.name);

  }

  ngAfterContentChecked(): void
  {
    this.currentProject = JSON.parse(sessionStorage.getItem(this.sessionCurrentProject) || '{}');

    // console.log("Current Project: ", this.currentProject, this.currentProject.name);

    
  }

  onChange() {
    console.log("onChangeLLMMode")
    this.llmModeLabel = this.isChecked ? "Accurate" : "Fast";

    // set the LLM mode
    sessionStorage.setItem('llmMode', this.isChecked ? '2' : '1');
    console.log("Mode set to ", localStorage.getItem('llmMode'))
    if (this.authenticationService.userValue){
      this.isLogged = true;
    }

  }

  setTheme(value: string): void {
    this.themeSwitch.setValue({ themeSwitchRadio: value });
    // this.classToggler.toggle('body', 'dark-theme');
  }

  logout() {

    if(confirm("Are you sure to exit the app?")) {
      console.log("Implement delete functionality here");
      this.authenticationService.logout();
      localStorage.clear();
      sessionStorage.clear();
      console.log("Logged out")
      this.isLogged = false;
    }
  }

handleLiveDemoChange(event: any) {
    this.visible = event;
  }

  toggleLiveDemo() {
    this.visible = !this.visible;
  }
}
