

  <ng-container>
  <c-container [fluid]="true" >

    <button
      toggle="visible"
      cHeaderToggler
      [cSidebarToggle]="sidebarId"
      class="ps-1"
      >
      <svg cIcon
        name="cilMenu"
        size="lg"
      ></svg>
    </button>
    <c-header-nav class="ms-auto me-4 ">
      <!-- <c-row>
        <c-col class="block-inline">
          <label cFormCheckLabel [ngStyle]="{'color': !isChecked ? '#BB86FC' : '#666'}">Fast&nbsp;&nbsp;</label>
        <c-form-check [switch]="true" >
          <input cFormCheckInput [(ngModel)]="isChecked" type="checkbox" sizing="lg"  (change)="onChange()"/>
        </c-form-check>
        <label cFormCheckLabel [ngStyle]="{'color': isChecked ? '#BB86FC' : '#666'}">&nbsp;&nbsp;Accurate</label>
      </c-col>
    </c-row> -->
    <span  class="periwinkle">{{this.currentProject.name}}</span>

    </c-header-nav>
    <div *ngIf="isLogged">
      <button (click)="logout()" cTooltip="Logout" size="sm" title="Logout" class="btn btn-dark"><svg class="ltg" [cIcon]="icons.cilAccountLogout" ></svg></button>
    </div>

  <!-- todo: header nav dropdown menus  -->

    <!--aside toggler-->
    <!-- <button
      toggle="visible"
      cHeaderToggler
      cSidebarToggle="aside1"
      class="px-md-0 me-md-3 d-none d-sm-block"
    >
      <svg cIcon name="cilApplicationsSettings" size="lg"></svg>
    </button> -->
  </c-container>
  <c-header-divider></c-header-divider>
  <c-container [fluid]="true">
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>

</ng-template>

<ng-template #messagesDropdown>

</ng-template>

<ng-template #notifyDropdown>
  
</ng-template>

<ng-template #taskDropdown>

</ng-template>