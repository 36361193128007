<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed"
  colorScheme="dark"
  id="sidebar1"
  visible
>
  <c-sidebar-brand
    [brandFull]="{
      src: 'assets/img/brand/logo.svg',
      width: 200,
      height: 80,
      alt: ' Logo'
    }"
    [brandNarrow]="{
      src: 'assets/img/brand/logo.svg',
      width: 46,
      height: 46,
      alt: ' Logo'
    }"
    [routerLink]="[]"
  />

    <!-- Left nav-bar -->
  <ng-scrollbar pointerEventsMethod="scrollbar">
    <c-sidebar-nav [navItems]="navItems" dropdownMode="close" />
  </ng-scrollbar>


    <c-sidebar-toggler
      *ngIf="!sidebar1.narrow"
      toggle="unfoldable"
      cSidebarToggle="sidebar1"
    />
  </c-sidebar>

<!--aside-->
<app-default-aside style="display: none;" />

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-default-header class="mb-4 d-print-none header header-sticky border" position="sticky" sidebarId="sidebar1" />
  <!--app-body-->
  <div class="body flex-grow-1 px-3">
    <c-container fluid>
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>
