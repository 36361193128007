<!--<c-footer>-->
  <div>
    <a href="https://sensori.ai" target="_blank">sensori.Ai</a> v0.34
    <span> &copy; 2024 All Rights Reserved</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://sensori.ai" target="_blank">
      <span>sensori.Ai</span>
    </a>
  </div>
<!--</c-footer>-->
